<template>
  <div>
    <Content :style="{ padding: '0 24px 16px' }">
      <div class="main-body">
        <div class="select-two main-search">
          <Select
            v-model="bytextType"
            style="width:110px"
            @on-change="resetGetInfo"
          >
            <Option
              v-for="item in textTypes"
              :value="item.typeValue"
              :key="item.value"
              >{{ item.type }}</Option
            >
          </Select>
          <Input
            prefix="ios-search"
            v-model="text"
            placeholder="请输入搜索内容"
            style="width: 280px"
            @on-enter="resetGetInfo"
          />

          <div class="st-block ml10">
            <div class="st-label">状态</div>
            <div class="st-select">
              <Select v-model="byType" @on-change="resetGetInfo">
                <!-- <Option v-for="item in devSearchLists" :value="item.value" :key="item.value">{{ item.label }}</Option> -->
                <Option value="0">全部</Option>
                <Option value="1">待保养</Option>
                <Option value="2">已保养</Option>
              </Select>
            </div>
          </div>
        </div>
        <div class="btn-area">
          <div class="btn-block" @click="exportData">
            <i class="icon1 icondaochu"></i>
            &nbsp;&nbsp;导出数据
          </div>
        </div>
        <div class="table-area">
          <Table
            class="devTable"
            max-height="750"
            no-data-text=" "
            border
            ref="selection"
            :loading="loading"
            :columns="theadDate"
            :data="tableDate"
          ></Table>
        </div>
        <div class="main-page">
          <Page
            :total="total"
            show-sizer
            show-elevator
            show-total
            :page-size="pageSize"
            :current="pageNum"
            @on-page-size-change="pagesizechange"
            @on-change="pagenumberchange"
          />
        </div>
      </div>
    </Content>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "", // 非必需，搜索内容
      byType: "0", // 非必需，保养状态：1待保养，2已保养',
      // byCode:"",
      // byCreattimme:"",
      // byDateplan:"",
      // deviceId:"",
      // deviceNum:"",
      // byContent:"",
      bytextType: "2", // 非必需，搜索内容的类型
      textTypes: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      pages: 0,
      workData: "",
      // 顶部筛选
      devSearchLists: [
        {
          value: "0",
          label: "设备型号"
        },
        {
          value: "1",
          label: "设备类型"
        },
        {
          value: "2",
          label: "生产厂家"
        }
      ],
      devSearchList: "", // 顶部第一个select选择的东西
      searchText: "", // 顶部搜索内容
      theadDate: [
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index + (this.pageNum - 1) * this.pageSize + 1
            );
          },
          align: "center",
          width: 48
        },
        {
          title: "操作",
          key: "action",
          minWidth: 100,
          align: "center",
          render: (h, params) => {
            const arr = [];
            if (params.row.byType === 1) {
              arr.push(
                h(
                  "Button",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    style: {
                      marginRight: "5px",
                      color: "#2980B9"
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                        this.$router.push({
                          path: "/deviceManage/addMaintaining",
                          query: {
                            byType: params.row.byType,
                            byCode: params.row.byCode,
                            byCreattimme: params.row.byCreattimme,
                            byDateplan: params.row.byDateplan,
                            deviceId: params.row.deviceId,
                            deviceNum: params.row.deviceNum,
                            byContent: params.row.byContent,
                            creatname: params.row.creatname,
                            serialNumber: params.row.serialNumber
                          }
                        });
                      }
                    }
                  },
                  "详情"
                )
              );
            } else {
              arr.push(
                h(
                  "Button",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    style: {
                      marginRight: "5px",
                      color: "#2980B9"
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                        this.$router.push({
                          path: "/deviceManage/maintainingDetail",
                          query: {
                            byType: params.row.byType,
                            byCode: params.row.byCode,
                            byCreattimme: params.row.byCreattimme,
                            byDateplan: params.row.byDateplan,
                            deviceId: params.row.deviceId,
                            deviceNum: params.row.deviceNum,
                            byContent: params.row.byContent,
                            creatname: params.row.creatname,
                            byId: params.row.byId
                          }
                        });
                      }
                    }
                  },
                  "详情"
                )
              );
            }

            return h("div", arr);
          }
        },
        {
          title: "状态",
          key: "byType",
          minWidth: 65,
          render: (h, params) => {
            const arr = [];
            if (params.row.byType === 1) {
              arr.push(
                h(
                  "div",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    style: {
                      marginRight: "5px",
                      padding: "0"
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                        //   this.$router.push({
                        //   path: "/deviceManage/addDevmaintenance",
                        //   query: { byType: params.row.byType }
                        // });
                      }
                    }
                  },
                  "待保养"
                )
              );
            } else {
              arr.push(
                h(
                  "div",
                  {
                    props: {
                      type: "text",
                      size: "small",
                      disabled: true
                    },
                    style: {
                      marginRight: "5px"
                    },
                    on: {
                      click: () => {
                        // this.$router.push({ path: './addInvitationrecharge?marketCode='+params.row.marketCode })
                        //   this.$router.push({
                        //   path: "/deviceManage/devmaintenanceDetail",
                        //   query: { byType: params.row.byType }
                        // });
                      }
                    }
                  },
                  "已保养"
                )
              );
            }

            return h("div", arr);
          }
        },
        {
          title: "保养单号",
          key: "byCode",
          className: "blueFont",
          minWidth: 125
        },
        {
          // title: '设备编号',
          title: "机台号",
          key: "deviceNum",
          minWidth: 60
        },
        {
          title: "计划保养日期",
          key: "byDateplan",
          minWidth: 105
        },
        {
          title: "制单人",
          key: "creatname",
          minWidth: 95
        },
        {
          title: "制单日期",
          key: "byCreattimme",
          minWidth: 90
        },
        {
          title: "保养人",
          key: "dealname",
          minWidth: 95
        },
        {
          title: "实际保养日期",
          key: "byDealtime",
          minWidth: 105
        }
      ],
      tableDate: [
        // {
        //   devstate:'2',
        //   state:2
        // }
      ],
      loading: false
    };
  },
  methods: {
    resetGetInfo() {
      this.pageSize = 10;
      this.pageNum = 1;
      this.searchList();
    },
    // 修改每页条数
    pagesizechange(pagesize) {
      this.pageSize = pagesize;
      this.pageNum = 1;
      this.searchList();
    },
    // 修改页码
    pagenumberchange(pagenum) {
      this.pageNum = pagenum;
      this.searchList();
    },
    // 导出数据
    exportData() {
      const that = this;
      window.location.href =
        that.$api.baseUrl + "/iext/back/device/DeviceController/exportMaintain";
      // useaxios
      // .axios(
      //   this.$api.baseUrl + '/iext/back/device/DeviceController/exportMaintain',
      //     {

      //       }
      //   )
      //   .then(res => {
      //     if (res.data.success === 1) {
      //         this.$Message.success("数据导出成功！")
      //       }
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
    // 当查询条件变化时调用
    searchList() {
      const that = this;
      this.axios({
        url: "/iext/back/device/DeviceController/dealMaintainList",
        method: "get",
        params: {
          text: that.text, // 非必需，搜索内容
          byType: that.byType, // 非必需，保养状态：1待保养，2已保养',
          bytextType: that.bytextType, // 非必需，搜索内容的类型
          pageSize: that.pageSize,
          pageNum: that.pageNum
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.tableDate = res.data.body.maintainList.result;
            that.total = res.data.body.maintainList.total;
            that.pages = res.data.body.maintainList.pages;
            that.pageSize = res.data.body.maintainList.pageSize;
            that.pageNum = res.data.body.maintainList.pageNum;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // p为不够10添加0的函数
    p(s) {
      return s < 10 ? "0" + s : s;
    },
    getTextType() {
      this.axios({
        url: "/iext/back/device/DeviceController/bytextType",
        method: "get",
        params: {}
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.textTypes = res.data.body.bytextType;
            this.searchList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  created() {},
  mounted() {
    this.getTextType();
  }
};
</script>

<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .select-one {
    margin: 16px 0;
  }
  .select-two {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .st-block {
      // height: $newIpt-height;
      display: flex;
      .st-label {
        margin-right: 10px;
      }
      .st-select {
        width: 108px;
        // height:3$newIpt-height;
      }
      .st-datepicker {
        width: 240px;
        // height:$newIpt-height;
      }
    }
    .ml10 {
      margin-left: 10px;
    }
  }
  .btn-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .btn-block {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }
    .btn-block:hover {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      color: #2980b9;
    }
  }
  .table-area {
    .devTable {
      table {
        // position: fixed !important;
      }
    }
    .ivu-table-wrapper /deep/ .ivu-table {
      .ivu-table-body {
        // overflow-y: scroll;
        //     max-height: 560px;
        //     overflow-x: inherit;

        // position: fixed !important;
        tbody .ivu-table-row:hover {
          td .ivu-table-cell .ivu-select .ivu-select-selection {
            border: none;
            box-shadow: none;
            background: rgba(235, 247, 255, 1);
          }
          td .ivu-table-cell .ivu-btn {
            border: none;
            box-shadow: none;
            background: rgba(235, 247, 255, 1);
          }
        }

        tbody .ivu-table-row {
          td .ivu-table-cell .ivu-select .ivu-select-selection {
            border: none;
            box-shadow: none;
            .ivu-select-selected-value {
              padding-left: 0;
            }
          }
          td .ivu-table-cell div {
            border: none;
            box-shadow: none;
            padding: 0px;
          }
          .blueFont {
            color: rgba(87, 163, 243, 1);
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.mainbody {
  .ivu-tabs-nav-container {
    /*background:rgba(228,235,241,1)*/
  }
  .el-tree-node__content {
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid rgba(223, 227, 232, 1);
  }
}
</style>
